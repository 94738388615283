body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: antiquewhite;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* ヘッダー設定 */
header.MuiAppBar-colorDefault {
  background-color: #fffaf3;
}
/* メニュー設定 */
.image-list{
  background-color: antiquewhite;
  margin:  0 auto;
  border: dashed;
  border-color: #f7950d;
}
.MuiImageListItem-root{
  padding-top: 30px;
}
.MuiImageList-root img.MuiImageListItem-img{
  width: 90%;
  margin: 0 auto;
}
/* カレンダー設定 */
.react-calendar{
  margin: 0 auto;
}
.md-up-calender .react-calendar{
  margin-top: 100px;
  margin-bottom: 100px;
  transform: scale(1.7, 1.7);
}
/* カレンダー設定：休業日セル */
.holiday > abbr {
  color: white;
}
.holiday.react-calendar__month-view__days__day abbr{
  color: white;
}
.holiday.react-calendar__month-view__days__day--weekend abbr{
  color: white;
}
.holiday ::before {
  color: red;
  content: '×';
  font-weight: 1000;
  font-size: x-large;
  position: absolute;
}
/* カレンダー設定：営業日セル */
.react-calendar__month-view__weekdays abbr {
  text-decoration: underline;
}
.react-calendar__month-view__days__day abbr{
  color: black;
}
.react-calendar__month-view__days__day--weekend abbr{
  color: black;
}

/* カレンダー設定：表示日セル（翌月のカレンダーは無効化） */
button.react-calendar__tile--active,
button.react-calendar__tile--active:enabled:hover,
button.react-calendar__tile--active:enabled:focus{
  background: #FABF6D;
}
div.now button.react-calendar__tile--active abbr,
div.now button.react-calendar__tile--active:enabled:hover abbr,
div.now button.react-calendar__tile--active:enabled:focus abbr{
  color: white;
}
.next-calender button.react-calendar__tile--active,
.next-calender button.react-calendar__tile--active:enabled:hover,
.next-calender button.react-calendar__tile--active:enabled:focus{
  background: #fff;
}
div.next button.react-calendar__tile--active,
div.next button.react-calendar__tile--active:enabled:hover,
div.next button.react-calendar__tile--active:enabled:focus{
  background: #fff;
  color: black;
}
div.next .next-calender button.react-calendar__tile--active,
div.next .next-calender button.react-calendar__tile--active:enabled:hover,
div.next .next-calender button.react-calendar__tile--active:enabled:focus{
  background: #fff;
  color: black;
}
.next-calender .react-calendar__tile--active {
  color: black;
}

.holiday.react-calendar__month-view__days__day--weekend abbr{
  color: white;
}